import _toConsumableArray from "C:/Users/10666/Desktop/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.promise.all-settled.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import axios from "axios";
export default {
  data: function data() {
    return {
      list: [{
        name: "公司新闻",
        child: []
      }, {
        name: "案例分享",
        child: []
      }, {
        name: "行业动态",
        child: []
      }],
      current: 0
    };
  },
  computed: {
    one: function one() {
      var list = this.list,
          current = this.current;
      return list[current].child[0] || {};
    }
  },
  created: function created() {
    this.getMonthBetween();
  },
  mounted: function mounted() {
    var _this = this;

    setTimeout(function () {
      _this.getPolling(_this.caseUrlArr, 1);

      _this.getPolling(_this.trendsUrlArr, 2);

      _this.getData("/news/6666-2/?timestamp=".concat(new Date().getTime()), 0);
    }, 2500);
  },
  methods: {
    getFour: function getFour(arr) {
      return arr.slice(1, 5);
    },
    getData: function getData(url, index) {
      var _this2 = this;

      return axios.get(url).then(function (res) {
        _this2.list[index].child = _this2.handleNews(res);
      });
    },
    getPolling: function getPolling(arr, index) {
      var _this3 = this;

      if (!this.list[index].child.length) {
        var caseArr = [];
        arr.forEach(function (item) {
          var url = "/news/".concat(item, "/?timestamp=").concat(new Date().getTime());
          caseArr.push(axios.get(url));
        });
        Promise.allSettled(caseArr).then(function (res) {
          var arrs = [];
          res.forEach(function (item) {
            if (item.status === 'fulfilled') {
              arrs = [].concat(_toConsumableArray(arrs), _toConsumableArray(_this3.handleNews(item.value)));
            }
          });
          _this3.list[index].child = arrs;
        }).catch(function () {});
      }
    },
    handleNews: function handleNews(res) {
      var objE = document.createElement("div");
      objE.innerHTML = res.data;
      var doms = objE.querySelectorAll(".elementor-widget-container"); // console.log(doms);

      var d = [];

      for (var i = 0, len = doms.length; i < len; i++) {
        var _dom$getElementsByTag, _dom$getElementsByTag2, _dom$getElementsByTag3, _dom$getElementsByTag4, _dom$getElementsByTag5;

        var dom = doms[i];
        var imgSrc = (_dom$getElementsByTag = dom.getElementsByTagName("img")[0]) === null || _dom$getElementsByTag === void 0 ? void 0 : _dom$getElementsByTag.src;
        var title = (_dom$getElementsByTag2 = dom.getElementsByTagName("h1")[0]) === null || _dom$getElementsByTag2 === void 0 ? void 0 : _dom$getElementsByTag2.innerText;
        var info = (_dom$getElementsByTag3 = dom.getElementsByTagName("h2")[0]) === null || _dom$getElementsByTag3 === void 0 ? void 0 : _dom$getElementsByTag3.innerText;
        var href = (_dom$getElementsByTag4 = dom.getElementsByTagName("h3")[0]) === null || _dom$getElementsByTag4 === void 0 ? void 0 : _dom$getElementsByTag4.innerText;
        var date = (_dom$getElementsByTag5 = dom.getElementsByTagName("h4")[0]) === null || _dom$getElementsByTag5 === void 0 ? void 0 : _dom$getElementsByTag5.innerText; // console.log(imgSrc, title, info, href);

        d.push({
          title: title,
          info: info,
          date: date,
          image: imgSrc,
          href: href
        });
      }

      return d;
    },
    getMonthBetween: function getMonthBetween() {
      var data = new Date();
      var year = data.getFullYear();
      var month = data.getMonth() + 1;
      var result = [];
      var min = new Date();
      var max = new Date();
      min.setFullYear(2024, 1);
      max.setFullYear(year, month);
      var curr = min;

      while (curr <= max) {
        var _month = curr.getMonth();

        var str = (_month == 0 ? curr.getFullYear() - 1 : curr.getFullYear()) + "-" + (_month == 0 ? 12 : _month < 10 ? "0" + _month : _month);
        var s = curr.getFullYear() + "-12";

        if (str == s) {
          str = curr.getFullYear() + "-12";
        }

        result.push(str);
        curr.setMonth(_month + 1);
      }

      this.caseUrlArr = result.map(function (item) {
        return "anli" + item;
      }).reverse();
      this.trendsUrlArr = result.map(function (item) {
        return "dtai" + item;
      }).reverse();
    },
    goNews: function goNews(url) {
      this.$router.push({
        path: "/child",
        query: {
          url: url
        }
      });
    }
  }
};